import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';

import BasePage from '../../layout/BasePage';
import Container from '../../layout/Container';

import MapCanvas from './components/MapCanvas';
import ProjectFilters from './components/ProjectFilters';
import ProjectsSummary from './components/ProjectsSummary';
import ProjectsList from './components/ProjectsList';
import ProjectsCharts from './components/ProjectsCharts';

import sampleData from '../../data/sampleData';

import {
  GET_BASE_DATA,
  GET_FILTERS_DATA
} from './query';

import styles from './LandingPage.module.scss';

function parseParams(params) {
  const {
    title,
    pci,
    ods,
    city,
  } = params;
  let parsedParams = {};

  if (!_.isEmpty(title)) {
    parsedParams.name = title;
  }

  if (!_.isEmpty(pci)) {
    parsedParams.metasPci = pci;
  }

  if (!_.isEmpty(ods)) {
    parsedParams.metasOds = ods;
  }
  
  if (!_.isEmpty(city)) {
    parsedParams.city = _.map(city, 'id');
  }


  return parsedParams;
}

export default function LandingPage() {
  const [params, setParams] = useState({
    title: '',
    pci: [],
    ods: [],
    city: [],
  });
  const { data: filtersData } = useQuery(GET_FILTERS_DATA);
  const { data: baseData } = useQuery(GET_BASE_DATA, {
    variables: parseParams(params),
  });
  const statistics = _.get(baseData, 'statisticProjects');
  const projects = _.get(baseData, 'searchProject');
  const cities = _.flatten(_.map(projects, 'city'));

  const handleFiltersSubmit = (params) => {
    setParams(params);
  };

  return (
    <BasePage>
      <header className={ styles.banner }>
        <div className={ styles.bannerContainer }>
          <div className={ styles.bannerContent }>
            <h1>
              <span>Pitchbook</span>
              <span>2023</span>
            </h1>
            <h2>Potenciais iniciativas para apoio técnico e financeiro rumo a implementação das metas da estratégia PCI no Mato Grosso.</h2>
            <p>Entenda como  empresas podem conectar seus esforços à estratégia PCI (Produzir, Conservar e Incluir) do Mato Grosso para contribuir com seu sucesso e atingir suas metas na cadeia de suprimento.</p>
            <Button
              variant="contained"
              color="primary"
              size="large"
            >
              Saiba mais
            </Button>
          </div>
        </div>
      </header>
      <Container>
        <div className={ styles.titleWrapper }>
          <h1 className={ styles.titleText }>Visão Geral</h1>
          <FilterListIcon className={ styles.titleIcon } />
        </div>
        <ProjectFilters
          data={ _.get(filtersData, 'filterPciOds') }
          values={ params }
          cities={ cities }
          onSubmit={ handleFiltersSubmit }
        />
      </Container>
      <MapCanvas
        heatMapActiveCities={ _.get(params, 'city') }
        heatMapCities={ cities }
      />
      <Container>
        <ProjectsCharts
          activePci={ _.map(_.get(params, 'pci'), (text) => text.replace(/["']/g, '')) }
          activeOds={ _.get(params, 'ods') }
          data={ statistics }
        />
        <ProjectsSummary data={ statistics } />
        <h2 className={ styles.projectsTitle }>
          <b>{ _.size(projects) }</b>
          <span>projetos</span>
        </h2>
        <ProjectsList data={ projects } />
      </Container>
    </BasePage>
  );
}

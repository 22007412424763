import React, { useEffect } from 'react';
import _ from 'lodash';

import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory
} from 'react-router-dom';

import LandingPage from '../../pages/LandingPage';
import ProjectLandingPage from '../../pages/ProjectLandingPage';
import LoginPage from '../../pages/LoginPage';
import ProjectsPage from '../../pages/ProjectsPage';
import ProjectPage from '../../pages/ProjectPage';
import ProjectsImportPage from '../../pages/ProjectsImportPage';

function ScrollToTop() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default function Routes() {
  const routesList = [
    { path: '/', component: LandingPage },
    { path: '/login', component: LoginPage },
    { path: '/projetos', component: ProjectsPage },
    { path: '/projetos/:id', component: ProjectLandingPage },
    { path: '/projetos/editar/:id', component: ProjectPage },
    { path: '/importar-projetos', component: ProjectsImportPage },
  ];

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        { routesList.map(({ path, component, render }) => {
          if (component) {
            return (
              <Route key={ `route-${ path }` } exact path={ path } component={ component } />
            );
          } else if (render) {
            return (
              <Route key={ `route-${ path }` } exact path={ path } render={ render } />
            );
          } else {
            return null;
          }
        }) }
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}

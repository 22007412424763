import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';

import BasePage from '../../layout/BasePage';
import Container from '../../layout/Container';

import {
  GET_BASE_DATA
} from './query';

import styles from './ProjectsPage.module.scss';

export default function ProjectsPage() {
  const { data: baseData, loading } = useQuery(GET_BASE_DATA, {
    fetchPolicy: 'no-cache'
  });
  const projects = _.get(baseData, 'allProject');
  const [searchText, setSearchText] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    if (projects) {
      setFilteredProjects(projects);
    }
  }, [projects]);

  useEffect(() => {
    let nextProjects = _.cloneDeep(projects);
    nextProjects = _.filter(nextProjects, ({ name }) => _.includes(_.kebabCase(name), _.kebabCase(searchText)));
    setFilteredProjects(nextProjects);
  }, [searchText]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <BasePage headerSimple>
      <Container>
        <header className={ styles.pageHeader }>
          <div className={ styles.pageHeaderTitleWrapper }>
            <h1>Projetos</h1>
            <OutlinedInput
              size="small"
              sx={ { width: 420 } }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={ () => {} }
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              value={ searchText }
              onChange={ handleSearchChange }
            />
          </div>
          <div className={ styles.pageHeaderButtons }>
            <Button variant="contained" color="primary" component={ Link } to="/importar-projetos">Importar</Button>
          </div>
        </header>
        <Paper className={ styles.box }>
          { loading &&
            <div className={ styles.boxLoadingWrapper }>
              <CircularProgress />
            </div>
          }
          { !loading && _.isEmpty(filteredProjects) &&
            <div className={ styles.boxLoadingWrapper }>
              <span>Nenhum projeto disponível. Realize a importação.</span>
            </div>
          }
          <ul className={ styles.projectsList }>
            { _.map(_.orderBy(filteredProjects, 'name', 'asc'), (item, index) => {
              const id = _.get(item, 'id');
              const title = _.get(item, 'name');
              const active = _.get(item, 'active');

              return (
                <li key={ `item-${ index }` }>
                  <span className={ styles.projectsListItemActiveTag } style={ { backgroundColor: active ? '#76B82A' : '#C5C5C5' } } />
                  <span className={ styles.projectsListItemTitle }>{ title }</span>
                  <Button variant="contained" color="secondary" component={ Link } to={ `/projetos/editar/${ id }` }>Editar</Button>
                </li>  
              );
            }) }
          </ul>
        </Paper>
      </Container>
    </BasePage>
  );
}


import { createTheme } from '@mui/material/styles';

import themeVariables from './variables';

const generateTheme = (mode = 'dark') => {
  const {
    greys,
    primaryColor,
    primaryColorLight,
    primaryColorDark,
    primaryColorContrastText,
    secondaryColor,
    secondaryColorLight,
    secondaryColorDark,
    secondaryColorContrastText
  } = themeVariables[mode];

  const palette = {
    background: {
      default: greys[100]
    },
    grey: greys,
    primary: {
      dark: primaryColorDark,
      light: primaryColorLight,
      main: primaryColor,
      highlight: '#EF9A02',
      highlightShadow: mode === 'dark' ? '#CFCFCF' : '#555',
      contrastText: primaryColorContrastText
    },
    secondary: {
      dark: secondaryColorDark,
      light: secondaryColorLight,
      main: secondaryColor,
      contrastText: secondaryColorContrastText
    },
    text: {
      primary: mode === 'dark' ? '#FFF' : '#333',
      secondary: mode === 'dark' ? '#FFF' : '#333'
    }
  };

  return createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: 'initial',
            fontWeight: 600
          },
          sizeMedium: {
            minWidth: 100
          },
          sizeLarge: {
            fontSize: 16,
            minWidth: 150
          },
          containedPrimary: {
            '&:hover': {
              backgroundColor: '#7cc22b'
            }
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiModal: {
        styleOverrides: {
          root: {
            zIndex: 9999
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            backgroundColor: '#F0F0F0'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#F0F0F0'
          }
        }
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0
        },
        styleOverrides: {
          root: {
            color: '#555',
            borderRadius: '20px',
            overflow: 'hidden'
          }
        }
      },
      MuiPopover: {
        defaultProps: {
          elevation: 1
        },
        styleOverrides: {
          root: {
            zIndex: 9999
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            zIndex: 9999,
          },
          tooltip: {
            padding: 8,
            background: 'white',
            color: '#444',
            fontSize: 12
          },
        }
      },
    },
  
    palette,
  
    typography: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: 12
    }
  });
};

export default generateTheme;

import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import logo from '../../images/logo.svg';

import useFormatMessage from '../../hooks/useFormatMessage';
import useWindowSize from '../../hooks/useWindowSize';

import toast from '../../utils/toast';

import styles from './LoginPage.module.scss';

const API_URL = process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:3000';

export default function LoginPage() {
  const formatMessage = useFormatMessage();
  const windowSize = useWindowSize();

  const handleSubmit = (params, { setSubmitting }) => {
    fetch(API_URL + '/auth/sign_in', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
      .then((resp) => resp.json())
      .then((data) => {
        const token = _.get(data, 'token');
        const error = _.get(data, 'error');

        if (token) {
          localStorage.setItem('token', token);
          window.location.href = '/';
        } else if (error === 'Invalid email or password') {
          toast(formatMessage('general.toast.invalid_email_or_password'), 'error');
        } else {
          toast(formatMessage('general.toast.default_form_error'), 'error');
        }

        setSubmitting(false);
      });
  };

  return (
    <div className={ styles.wrapper } style={ { height: windowSize.height } }>
      <div className={ styles.contentWrapper } style={ { height: windowSize.height } }>
        <div className={ styles.content }>
          <Link to="/">
            <img className={ styles.logoImage } src={ logo } />
          </Link>
          <Paper className={ styles.box }>
            <header className={ styles.boxHeader }>
              <h1>
                <span>Pitchbook</span>
                <span>2023</span>
              </h1>
            </header>
            <div className={ styles.boxContentWrapper }>
              <h1 className={ styles.title }><FormattedMessage id="login_page.title" /></h1>
              <p className={ styles.description }><FormattedMessage id="login_page.description" /></p>
              <Formik
                initialValues={ {
                  email: '',
                  password: '',
                } }
                validationSchema={ yup.object({
                  email: yup
                    .string()
                    .email(formatMessage('general.form_validation.email'))
                    .required(formatMessage('general.form_validation.required')),
                  password: yup
                    .string()
                    .required(formatMessage('general.form_validation.required')),
                }) }
                onSubmit={ handleSubmit }
              >
                { ({ isSubmitting }) => (
                  <Form>
                    <Field
                      fullWidth
                      className={ styles.formControl }
                      component={ TextField }
                      name="email"
                      label={ formatMessage('login_page.form.fields.email') }
                      type="email"
                    />
                    <Field
                      fullWidth
                      className={ styles.formControl }
                      component={ TextField }
                      name="password"
                      label={ formatMessage('login_page.form.fields.password') }
                      type="password"
                    />
                    <div className={ styles.actionsWrapper }>
                      <Link to="/login" className={ styles.passwordResetLink }>
                        <FormattedMessage id="login_page.forgot_password" />
                      </Link>
                      <Button
                        disabled={ isSubmitting }
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                      >
                        <FormattedMessage id="login_page.form.buttons.submit" />
                      </Button>
                    </div>
                    <p className={ styles.infoText }>
                      <FormattedMessage id="login_page.dont_have_account" />
                      <Link to="/login"><FormattedMessage id="login_page.sign_up_action" /></Link>
                    </p>
                  </Form>
                ) }
              </Formik>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}

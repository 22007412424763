import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Masonry from 'react-masonry-css';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import imageSample from './sample-project-image.png';

import styles from './ProjectsList.module.scss';

const API_URL = process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:3000';

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

function ProjectCard({
  data
}) {
  const id = _.get(data, 'id');
  const title = _.get(data, 'name');
  const goal = _.get(data, 'objective');
  const image = _.get(data, 'image');

  return (
    <div className={ styles.projectCard }>
      <Link to={ '/projetos/' + id }>
        <div className={ styles.projectCardImage } style={ { backgroundImage: `url(${ image ? (API_URL + image) : imageSample })` } } />
        <div className={ styles.projectCardContent }>
          <h2 className={ styles.projectCardTitle }>
              { title }
          </h2>
          <ul className={ styles.projectCardTagList }>
            <li>{ _.size(_.get(data, 'city')) } municípios</li>
            {/* // { _.map(locationItems, (location) => {
            //   return (
            //     <li key={ `location-${ _.kebabCase(location) }` }>{ location }</li>
            //   );
            // }) } */}
          </ul>
          <p className={ styles.projectCardDescription }>{ goal }</p>
          <Button variant="contained" color="primary" component={ Link } to={ '/projetos/' + id }>Saiba mais</Button>
        </div>
      </Link>
    </div>
  );
}

export default function ProjectsList({
  data,
}) {
  return (
    <Masonry
      breakpointCols={ breakpointColumnsObj }
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      { _.map(_.orderBy(data, 'name', 'asc'), (item) => {
        return (
          <ProjectCard
            key={ `project-${ item.id }` }
            data={ item }
          />
        );
      }) }
    </Masonry>
  );
}


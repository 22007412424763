export default {
  dark: {
    primaryColor: '#76B82A',
    primaryColorLight: '#81c830',
    primaryColorDark: '#6aa626',
    primaryColorContrastText: '#FFF',
    
    secondaryColor: '#174B86',
    secondaryColorLight: '#174B86',
    secondaryColorDark: '#174B86',
    secondaryColorContrastText: '#FFF',
    
    greys: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    }
  },
  light: {
    primaryColor: '#76B82A',
    primaryColorLight: '#76B82A',
    primaryColorDark: '#76B82A',
    primaryColorContrastText: '#FFF',
    
    secondaryColor: '#174B86',
    secondaryColorLight: '#174B86',
    secondaryColorDark: '#174B86',
    secondaryColorContrastText: '#FFF',

    greys: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    }
  },
}

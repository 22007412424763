import React from 'react';
import classnames from 'classnames';

import Header from '../Header';

import styles from './BasePage.module.scss';

export default function BasePage({
  headerSimple = false,
  children,
  className,
}) {
  return (
    <div className={ classnames(styles.pageWrapper, className) }>
      <Header
        simple={ headerSimple }
      />
      <div id="app-content" className={ styles.contentWrapper }>
        { children }
      </div>
    </div>
  );
}

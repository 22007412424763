import { gql } from '@apollo/client';

export const GET_FILTERS_DATA = gql`
  query GetFiltersData {
    filterPciOds {
      pci {
        description
      }
      ods{
        id
        name
        code
        description
      }
      city {
        code
        id
        name
      }
    }
  }
`;

export const GET_BASE_DATA = gql`
  query GetBaseData(
    $name: String
    $metasPci: [String!]
    $metasOds: [Int!]
    $city: [Int!]
  ) {
    searchProject(
      name: $name
      metasPci: $metasPci
      metasOds: $metasOds
      city: $city
      active: true
    ) {
      active
      description
      duration
      financiers
      id
      code
      image
      indicator {
        id
        avoidedDeforestationHa
        benefitedFamilies
        benefitedProperties
        category
        demandResourcesScale
        financingCarried
        metasOds
        metasPci
        recoveredPasture
        restoredHectares
        yearReport
        investmentMade
        indicatorsAndMetrics
      }
      investiment
      location
      name
      objective
      partnerInstitutions
      product
      projectGoal
      proposingInstitution
      targetAudience
      website
      support
      contact
      pci {
        category
        description
        code
        typePci
        id
      }
      ods {
        code
        description
        name
        id
      }
      city {
        id
        code
        name
      }
      pci {
        category
        description
        code
        typePci
        id
      }
      ods {
        code
        description
        name
        id
      }
      city {
        id
        code
        name
      }
    }

    statisticProjects(
      name: $name
      metasPci: $metasPci
      metasOds: $metasOds
      city: $city
      active: true
    ) {
      benefitedFamilies
      benefitedProperties
      ods {
        category
        count
        description
        id
        name
        typePci
        code
      }
      pci {
        category
        count
        description
        id
        name
        typePci
      }
      pciInfos {
        category
        count
        description
        id
        name
        typePci
      }
      restoredHectares
    }
  }
`;

import { gql } from '@apollo/client';

// TODO: Confirm query name

export const GET_BASE_DATA = gql`
  query GetBaseData {
    allProject {
      active
      description
      duration
      financiers
      id
      code
      image
      indicator {
        id
        avoidedDeforestationHa
        benefitedFamilies
        benefitedProperties
        category
        demandResourcesScale
        financingCarried
        metasOds
        metasPci
        recoveredPasture
        restoredHectares
        yearReport
        investmentMade
        indicatorsAndMetrics
      }
      investiment
      location
      name
      objective
      partnerInstitutions
      product
      projectGoal
      proposingInstitution
      targetAudience
      website
      support
      contact
      pci {
        category
        description
        code
        typePci
        id
      }
      ods {
        code
        description
        name
        id
      }
      city {
        id
        code
        name
      }
      pci {
        category
        description
        code
        typePci
        id
      }
      ods {
        code
        description
        name
        id
      }
      city {
        id
        code
        name
      }
    }
  }
`;

import React from 'react';
import { useQuery } from '@apollo/client';

import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import Snackbar from '@mui/material/Snackbar';

import { toastVar } from '../../core/apollo/apollo-cache';

import {
  GET_BASE_DATA
} from './query';

export default function ToastContainer() {
  const { data: baseData } = useQuery(GET_BASE_DATA);

  if (!baseData) { return null; }

  const { toast } = baseData;
  const { content, severity, visible } = toast;

  const handleClose = () => {
    toastVar({
      ...toast,
      visible: false
    });
  };

  return (
    <Snackbar
      anchorOrigin={ {
        vertical: 'top',
        horizontal: 'center'
      } }
      open={ visible }
      autoHideDuration={ 5000 }
      onClose={ handleClose }
      TransitionComponent={ Fade }
    >
      <Alert
        elevation={ 1 }
        onClose={ handleClose }
        severity={ severity || 'info' }
      >
        { content }
      </Alert>
    </Snackbar>
  );
}

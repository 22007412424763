export default {
  pt: {
    login_page: {
      title: 'Login',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      form: {
        fields: {
          email: 'E-mail',
          password: 'Senha'
        },
        buttons: {
          submit: 'Entrar'
        }
      },
      forgot_password: 'Esqueci minha senha',
      dont_have_account: 'Não tem conta?',
      sign_up_action: 'Registre-se'
    },
    general: {
      form_validation: {
        email: 'É necessário inserir um e-mail válido.',
        required: 'Este campo é obrigatório.',
        password_length: 'É necessário inserir no mínimo 6 caracteres.',
        password_confirmation: 'A senha e confirmação de senha devem ser iguais.'
      },
      toast: {
        invalid_email_or_password: 'E-mail ou senha inválidos.',
        inative_user: 'Usuário inativo. Aguarde a confirmação do seu cadastro.',
        default_form_error: 'Ocorreu um erro! Verifique os campos preenchidos e tente novamente.',
        reset_password_confirmation: 'Verifique o e-mail informado para acessar as informações de cadastro da nova senha.',
        password_updated: 'Senha atualizada com sucesso! Realize o login.',
        duplicate_email: 'A validação falhou: Esse e-mail já está cadastrado no sistema.'
      },
    }
  },
  en: {
    login_page: {
      title: 'Login',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      form: {
        fields: {
          email: 'Email',
          password: 'Password'
        },
        buttons: {
          submit: 'Login'
        }
      },
      forgot_password: 'I forgot my password',
      dont_have_account: 'Don\'t have an account?',
      sign_up_action: 'Register'
    },
    general: {
      form_validation: {
        email: 'You must enter a valid email address.',
        required: 'This field is required.',
        password_length: 'You must enter at least 6 characters.',
        password_confirmation: 'The password and password confirmation must be the same.'
      },
      toast: {
        invalid_email_or_password: 'Invalid email or password.',
        inative_user: 'Inactive user. Wait for confirmation of your registration.',
        default_form_error: 'An error has occurred! Check the fields filled in and try again.',
        reset_password_confirmation: 'Check the email provided to access the registration information for the new password.',
        password_updated: 'Password updated successfully! Log in.',
        duplicate_email: 'Validation failed: This email is already registered in the system.'
      },
    }
  }
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListIcon from '@mui/icons-material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoginIcon from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';

import logo from '../../images/logo.svg';

import styles from './Header.module.scss';

export default function Header({ simple }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const token = localStorage.getItem('token');

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleExit = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  return (
    <header className={ styles.wrapper }>
      <div className={ styles.container }>
        <Link to="/">
          <img src={ logo } className={ styles.logoImage } />
        </Link>
        <nav className={ styles.nav }>
          { !simple &&
            <ul className={ styles.navLinks }>
              <li><a href="#">Apresentação</a></li>
              <li><a href="#">Metas</a></li>
              <li><a href="#">Implementação</a></li>
              <li><a href="#">O instituto PCI</a></li>
              <li><a href="#">Contato</a></li>
            </ul>
          }
          { !token &&
            <Button
              component={ Link }
              to="/login"
              variant="contained"
              startIcon={ <LoginIcon /> }
            >
              Entrar
            </Button>
          }
          { token &&
            <IconButton
              onClick={ handleMenuOpen }
              size="large"
            >
              <Avatar sx={{ backgroundColor: '#76B82A' }}>
                <PersonIcon />
              </Avatar>
            </IconButton>
          }
        </nav>
        <Menu
          id="account-menu"
          anchorEl={ menuAnchorEl }
          open={ Boolean(menuAnchorEl) }
          onClose={ handleMenuClose }
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem component={ Link } to="/projetos">
            <ListItemIcon>
              <ListIcon fontSize="small" />
            </ListItemIcon>
            Projetos
          </MenuItem>
          <MenuItem onClick={ handleExit }>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Sair
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
}

import React, { Fragment } from 'react';
import {
  GeoJSON,
  MapContainer,
  Marker,
  Popup,
  TileLayer
} from 'react-leaflet'
import _ from 'lodash';
import L from 'leaflet';
import chroma from 'chroma-js';

import Button from '@mui/material/Button';

import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import image from '../ProjectsList/sample-project-image.png';

import citiesGeoJSON from './cities-geojson.json';

import styles from './MapCanvas.module.scss';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow,
});

export default function MapCanvas({
  cities,
  heatMapActiveCities,
  heatMapCities,
  data
}) {
  const renderMarkers = () => {
    return _.map(data, (item) => {
      const title = _.get(item, 'Nome no projeto no MT');

      return (
        <Marker position={ _.get(item, 'location') }>
          <Popup>
            <div className={ styles.popupContent }>
              <img src={ image } />
              <h2>{ title }</h2>
              <Button variant="contained" color="primary">Saiba mais</Button>
            </div>
          </Popup>
        </Marker>
      );
    });
  };

  const onEachFeature = (feature, layer) => {
    layer.on('click', function () {
      this._path.setAttribute('style', 'outline: none');
    });
    const tooltipContent = `
      <div class="tooltip-container">
        <span class="tooltip-title">${ feature.properties.name }</h1>
      </div>
    `;

    layer.bindTooltip(tooltipContent, {
      direction: 'top',
      className: 'custom-tooltip-class'
    });
  };

  const renderCitiesLayer = () => {
    const validCityCodes = _.map(cities, 'code');
    const featuresClone = _.cloneDeep(citiesGeoJSON.features);
    const filteredFeatures = _.filter(featuresClone, (f) => {
      const code = Number(_.get(f, 'properties.id'));

      return _.includes(validCityCodes, code);
    });
    const customData = {
      type: 'FeatureCollection',
      features: filteredFeatures
    };

    return (
      <GeoJSON
        key={ `map-${ _.join(validCityCodes) }` }
        data={ customData }
        style={ () => {
          return {
            fillColor: '#76B82A',
            color: 'white',
            weight: 1,
            fillOpacity: 1
          };
        } }
        onEachFeature={ onEachFeature }
      />
    );
  };

  const renderHeatMapLayer = () => {
    const countCities = _.countBy(heatMapCities, 'code');
    const citiesCodes = _.keys(countCities);
    const countCitiesValues = _.values(countCities);

    const sizesMax = _.max(countCitiesValues);
    const sizesMin = _.min(countCitiesValues);
  
    const getColor = chroma.scale(['#bdc3c7', '#2c3e50']).mode('lch');
  
    const getPercentage = (v) => ((v - sizesMin) * 100) / (sizesMax - sizesMin);

    const featuresClone = _.cloneDeep(citiesGeoJSON.features);
    const filteredFeatures = _.filter(featuresClone, (f) => {
      const code = _.get(f, 'properties.id');

      if (_.isEmpty(heatMapActiveCities)) {
        return _.includes(citiesCodes, code);
      } else {
        const activeCodes = _.map(heatMapActiveCities, 'code');

        return _.includes(citiesCodes, code) && _.includes(activeCodes, Number(code));
      }
    });
    const customData = {
      type: 'FeatureCollection',
      features: filteredFeatures
    };

    const onEachFeature = (feature, layer) => {
      layer.on('click', function () {
        this._path.setAttribute('style', 'outline: none');
      });
      const tooltipContent = `
        <div class="tooltip-container">
          <span class="tooltip-title">${ feature.properties.name } - ${ _.get(countCities, feature.properties.id) }</h1>
        </div>
      `;
  
      layer.bindTooltip(tooltipContent, {
        direction: 'top',
        className: 'custom-tooltip-class'
      });
    };  

    return (
      <Fragment>
        <GeoJSON
          key={ `map-${ _.join(citiesCodes) }-${ _.join(heatMapActiveCities) }` }
          data={ customData }
          style={ (f) => {
            const value = _.get(countCities, _.get(f, 'properties.id'));
            const percentage = getPercentage(value) / 100;
            const color = getColor(percentage).hex();

            return {
              fillColor: color,
              fillOpacity: 1,
              color: 'white',
              weight: 1,
            };
          } }
          onEachFeature={ onEachFeature }
        />
        <div className={ styles.legendBox }>
          <div className={ styles.legendBoxLabel }>
            <span>{ sizesMin }</span>
            <span>{ sizesMax }</span>
          </div>
          <div className={ styles.legendBoxLinearGradient } />
          <p className={ styles.legendBoxTitle }>Total de projetos por município</p>
        </div>
      </Fragment>
    );
  };

  return (
    <MapContainer
      style={ { height: '580px' } }
      center={ [-12.811801316582619, -56.54663085937501] }
      zoom={ 6 }
      scrollWheelZoom={ false }
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution="(C) OpenStreetMap contributors (C) CARTO"
      />
      { renderMarkers() }
      { !cities && renderHeatMapLayer() }
      { cities && renderCitiesLayer() }
    </MapContainer>
  );
}

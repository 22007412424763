import { gql } from '@apollo/client';

export const GET_FIELDS_DATA = gql`
  query GetFieldsData {
    allPci {
      id
      category
      code
      description
      typePci
    }
    allOds {
      id
      description
      name
      code
    }
    allCity {
      id
      code
      name
    }
  }
`;

export const GET_BASE_DATA = gql`
  query GetBaseData(
    $id: Int
  ) {
    searchProject(
      id: $id
    ) {
      active
      description
      duration
      financiers
      id
      code
      image
      indicator {
        id
        avoidedDeforestationHa
        benefitedFamilies
        benefitedProperties
        category
        demandResourcesScale
        financingCarried
        metasOds
        metasPci
        recoveredPasture
        restoredHectares
        yearReport
        investmentMade
        indicatorsAndMetrics
      }
      investiment
      location
      name
      objective
      partnerInstitutions
      product
      projectGoal
      proposingInstitution
      targetAudience
      website
      support
      contact
      pci {
        category
        description
        code
        typePci
        id
      }
      ods {
        code
        description
        name
        id
      }
      city {
        id
        code
        name
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $attributes: ProjectAttributes!,
  ) {
    updateProject(
      attributes: $attributes
    ) {
      response {
        description
        duration
        financiers
        id
        indicator {
          id
          avoidedDeforestationHa
          benefitedFamilies
          benefitedProperties
          category
          demandResourcesScale
          financingCarried
          metasOds
          metasPci
          recoveredPasture
          restoredHectares
        }
        investiment
        location
        name
        objective
        partnerInstitutions
        product
        projectGoal
        proposingInstitution
        targetAudience
        website
        support
        contact
        pci {
          category
          description
          code
          typePci
          id
        }
        ods {
          code
          description
          name
          id
        }
        city {
          id
          code
          name
        }
      }
    }
  }
`;

export const SET_PROJECT_STATUS = gql`
  mutation ActiveProject(
    $id: Int!,
    $active: Boolean!,
  ) {
    activeProject(
      id: $id,
      active: $active,
    ) {
      response {
        active
        description
        duration
        financiers
        id
        indicator {
          id
          avoidedDeforestationHa
          benefitedFamilies
          benefitedProperties
          category
          demandResourcesScale
          financingCarried
          metasOds
          metasPci
          recoveredPasture
          restoredHectares
        }
        investiment
        location
        name
        objective
        partnerInstitutions
        product
        projectGoal
        proposingInstitution
        targetAudience
        website
        support
        contact
        pci {
          category
          description
          code
          typePci
          id
        }
        ods {
          code
          description
          name
          id
        }
        city {
          id
          code
          name
        }
      }
    }
  }
`;

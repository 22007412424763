import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import classnames from 'classnames';
import numbro from 'numbro';

import Grid from '@mui/material/Grid';

import BasePage from '../../layout/BasePage';

import MapCanvas from '../LandingPage/components/MapCanvas';

import styles from './ProjectLandingPage.module.scss';

import bannerImage from './banner.png';

import ods1 from '../../images/ods/ODS-1.svg';
import ods2 from '../../images/ods/ODS-2.svg';
import ods3 from '../../images/ods/ODS-3.svg';
import ods4 from '../../images/ods/ODS-4.svg';
import ods5 from '../../images/ods/ODS-5.svg';
import ods6 from '../../images/ods/ODS-6.svg';
import ods7 from '../../images/ods/ODS-7.svg';
import ods8 from '../../images/ods/ODS-8.svg';
import ods9 from '../../images/ods/ODS-9.svg';
import ods10 from '../../images/ods/ODS-10.svg';
import ods11 from '../../images/ods/ODS-11.svg';
import ods12 from '../../images/ods/ODS-12.svg';
import ods13 from '../../images/ods/ODS-13.svg';
import ods14 from '../../images/ods/ODS-14.svg';
import ods15 from '../../images/ods/ODS-15.svg';
import ods16 from '../../images/ods/ODS-16.svg';
import ods17 from '../../images/ods/ODS-17.svg';

import {
  GET_BASE_DATA
} from './query';

const API_URL = process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:3000';

const mapODSGoalToImage = {
  '1': ods1,
  '2': ods2,
  '3': ods3,
  '4': ods4,
  '5': ods5,
  '6': ods6,
  '7': ods7,
  '8': ods8,
  '9': ods9,
  '10': ods10,
  '11': ods11,
  '12': ods12,
  '13': ods13,
  '14': ods14,
  '15': ods15,
  '16': ods16,
  '17': ods17,
};

function formatNumber(value) {
  return numbro(Number(value)).format({
    thousandSeparated: true
  });
}

export default function ProjectLandingPage(props) {
  const id = Number(_.get(props, 'match.params.id'));
  const { data: baseData } = useQuery(GET_BASE_DATA, {
    variables: {
      id
    },
  });
  const project = _.first(_.get(baseData, 'searchProject'));
  const financingIndicator = _.find(_.get(project, 'indicator'), { category: 'Financiamento' });

  const getIndicatorValue = (category, valueKey) => {
    const indicator = _.find(_.get(project, 'indicator'), (i) => {
      return i.category === category && i[valueKey];
    });

    return _.get(indicator, valueKey);
  };

  const title = _.get(project, 'name');
  const goal = _.get(project, 'objective');
  const image = _.get(project, 'image');

  const renderPCIGoals = () => {
    const pciGoals = _.get(project, 'pci');

    return _.map(pciGoals, (item) => {
      const {
        typePci,
        description
      } = item;

      return (
        <div
          className={ classnames(styles.pciGoal, {
            [styles.pciGoalColorGreen]: typePci === 'CONSERVAR',
            [styles.pciGoalColorBlue]: typePci === 'PRODUZIR',
            [styles.pciGoalColorYellow]: typePci === 'INCLUIR',
          }) }
        >
          <span className={ classnames(styles.pciGoalTag, {
            [styles.pciGoalTagColorGreen]: typePci === 'CONSERVAR',
            [styles.pciGoalTagColorBlue]: typePci === 'PRODUZIR',
            [styles.pciGoalTagColorYellow]: typePci === 'INCLUIR',
          }) }>{ typePci }</span>
          <p className={ styles.pciGoalContent }>{ description }</p>
        </div>
      );
    });
  };

  const renderODSGoals = () => {
    const odsGoals = _.get(project, 'ods');
  
    return _.map(odsGoals, ({ code }) => {
      const image = mapODSGoalToImage[code];

      return (
        <Grid item xs={ 4 } key={ `ods-${ code }` }>
          <img className={ styles.odsImage } src={ image } />
        </Grid>
      );
    });
  };

  const renderSummary = (category = 'RA', dataType = 'value') => {
    const indicator = _.find(_.get(project, 'indicator'), { category });
    const propertiesTotal = _.get(indicator, 'benefitedProperties');
    const familiesTotal = _.get(indicator, 'benefitedFamilies');
    const restoredAreaTotal = _.get(indicator, 'restoredHectares');
    const deforestationAreaTotal = _.get(indicator, 'avoidedDeforestationHa');
  
    return (
      <Grid container spacing={ 2 } justifyContent="center">
        <Grid item xs={ 6 }>
          <div className={ styles.tableRowContentItem }>
            <span className={ styles.tableRowContentListValue }>{ propertiesTotal || '-' }</span>
            <span className={ styles.tableRowContentListLabel }>Propriedades beneficiadas</span>
          </div>
        </Grid>
        <Grid item xs={ 6 }>
          <div className={ styles.tableRowContentItem }>
            <span className={ styles.tableRowContentListValue }>{ familiesTotal || '-' }</span>
            <span className={ styles.tableRowContentListLabel }>Famílias beneficiadas</span>
          </div>
        </Grid>
        <Grid item xs={ 6 }>
          <div className={ styles.tableRowContentItem }>
            { dataType === 'value' && !_.isUndefined(restoredAreaTotal) && <span className={ styles.tableRowContentListValue }>{ restoredAreaTotal } <small>ha</small></span> }
            { dataType === 'value' && _.isUndefined(restoredAreaTotal) && <span className={ styles.tableRowContentListValue }>-</span> }
            <span className={ styles.tableRowContentListLabel }>Hectares restaurados</span>
          </div>
        </Grid>
        <Grid item xs={ 6 }>
          <div className={ styles.tableRowContentItem }>
            { dataType === 'value' && !_.isUndefined(deforestationAreaTotal) && <span className={ styles.tableRowContentListValue }>{ deforestationAreaTotal } <small>ha</small></span> }
            { dataType === 'value' && _.isUndefined(deforestationAreaTotal) && <span className={ styles.tableRowContentListValue }>-</span> }
            <span className={ styles.tableRowContentListLabel }>Desmatamento evitado (ha)</span>
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <BasePage className={ styles.page }>
      <header className={ styles.banner } style={ { backgroundImage: `url(${ image ? (API_URL + image) : bannerImage })` } }>
        <div className={ styles.bannerContainer }>
          <div className={ styles.bannerContent }>
            <h1>
              <span>{ title }</span>
            </h1>
            <p>{ goal }</p>
          </div>
        </div>
      </header>
      <div className={ styles.container }>
        <Grid container spacing={ 5 } justifyContent="center">
          <Grid item xs={ 8 }>
            <h1 className={ styles.sectionTitleSimpleLarge }>O projeto</h1>
            <div className={ styles.boxTextBlock }>
              <h2 className={ styles.titleSimple }>Objetivo</h2>
              <p className={ styles.boxTextSmall }>{ _.get(project, 'objective') }</p>
            </div>
            <div className={ styles.boxTextBlock }>
              <h2 className={ styles.titleSimple }>Descrição</h2>
              <p className={ styles.boxTextSmall }>{ _.get(project, 'description') }</p>
            </div>
            <div className={ styles.boxTextBlock }>
              <h2 className={ styles.titleSimple }>Público Alvo</h2>
              <p className={ styles.boxTextSmall }>{ _.get(project, 'targetAudience') }</p>
            </div>
            <div className={ styles.boxTextBlock }>
              <h2 className={ styles.titleSimple }>Meta</h2>
              <p className={ styles.boxTextSmall }>{ _.get(project, 'projectGoal') }</p>
            </div>
          </Grid>
          <Grid item xs={ 4 }>
            <div className={ styles.sectionSide }>
              <h2 className={ styles.sectionTitleSimple }>Metas PCI</h2>
              { renderPCIGoals() }
            </div>
            <div className={ styles.sectionSide }>
              <h2 className={ styles.sectionTitleSimple }>Objetivos de desenvolvimento sustentável</h2>
              <Grid container spacing={ 2 }>
                { renderODSGoals() }
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <section className={ styles.locationSection }>
        <div className={ styles.container }>
          <div className={ styles.boxTextBlock }>
            <h2 className={ styles.titleSimple }>Localidade</h2>
          </div>
        </div>
        <MapCanvas cities={ _.get(project, 'city') } />
      </section>
      <div className={ styles.containerFinancing }>
        <Grid container spacing={ 5 }>
          <Grid item xs={ 6 }>
            <div className={ styles.boxTextBlock }>
              <h2 className={ styles.titleSimple }>Instituição Proponente</h2>
              <p className={ styles.boxTextSmall }>{ _.get(project, 'proposingInstitution') }</p>
            </div>
          </Grid>
          <Grid item xs={ 6 }>
            <div className={ styles.boxTextBlock }>
              <h2 className={ styles.titleSimple }>Instituições Parceiras</h2>
              <p className={ styles.boxTextSmall }>{ _.get(project, 'partnerInstitutions') }</p>
            </div>
          </Grid>
          <Grid item xs={ 6 }>
            <div className={ styles.boxTextBlock }>
              <h2 className={ styles.titleSimple }>Duração do projeto</h2>
              <p className={ styles.boxTextSmall }>{ _.get(project, 'duration') }</p>
            </div>
          </Grid>
          <Grid item xs={ 6 }>
            <div className={ styles.boxTextBlock }>
              <h2 className={ styles.titleSimple }>Financiador</h2>
              <p className={ styles.boxTextSmall }>{ _.get(project, 'financiers') }</p>
            </div>
          </Grid>
          <Grid item xs={ 6 }>
            <div className={ styles.boxTextBlock }>
              <h2 className={ styles.titleSimple }>Financiamento</h2>
              <p className={ styles.boxTextSmall }>{ _.get(project, 'investiment') }</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <section className={ styles.boxBlue }>
        <div className={ styles.container }>
          <h2 className={ styles.titleTextLarge }>Resultados alcançados</h2>
          <Grid container spacing={ 4 } justifyContent="center">
            <Grid item xs={ 7 }>
              <h3 className={ styles.subSectionTitle }>Indicadores e métricas</h3>
              <p className={ styles.boxTextSmall } dangerouslySetInnerHTML={ { __html: getIndicatorValue('Resultados Alcançados – Informações', 'indicatorsAndMetrics') } } />
            </Grid>
            <Grid item xs={ 5 }>
              <h3 className={ styles.subSectionTitle } style={ { textAlign: 'right' } }>Financiamento / Investimento realizado</h3>
              <p className={ styles.boxTextHighlightNumber } dangerouslySetInnerHTML={ { __html: `R$ ${ formatNumber(getIndicatorValue('Resultados Alcançados – Informações', 'investmentMade')) }` } } />
            </Grid>
          </Grid>
          <div className={ styles.tableGridItem }>
            <Grid container spacing={ 4 }>
              <Grid item xs={ 4 }>
                <h2 className={ styles.tableRowTitle }>Metas do Projeto</h2>
              </Grid>
              <Grid item xs={ 8 }>
                { renderSummary('Metas do projeto', 'value') }
              </Grid>
            </Grid>
          </div>
          <div className={ styles.tableGridItem }>
            <Grid container spacing={ 4 }>
              <Grid item xs={ 4 }>
                <h2 className={ styles.tableRowTitle }>Resultados alcançados</h2>
              </Grid>
              <Grid item xs={ 8 }>
                { renderSummary('Resultados Alcançados – Estatísticas', 'value') }
              </Grid>
            </Grid>
          </div>
          <div className={ styles.boxSummary }>
            <Grid container spacing={ 2 } justifyContent="center">
              <Grid item xs={ 6 }>
                <h2 className={ styles.totalInfo }>
                  <span>Financiamento realizado/em execução</span>
                  <b>R$ { formatNumber(_.get(financingIndicator, 'financingCarried')) }</b>
                </h2>
              </Grid>
              <Grid item xs={ 6 }>
                <h2 className={ styles.totalInfo }>
                  <span>Demanda de recursos para escala</span>
                  <b>R$ { formatNumber(_.get(financingIndicator, 'demandResourcesScale')) }</b>
                </h2>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
      <div className={ styles.container }>
        <div className={ styles.boxCenterContentGray }>
          <Grid container spacing={ 4 }>
            <Grid item xs={ 6 }>
              <div className={ styles.boxTextBlock }>
                <h2 className={ styles.titleText }>Como apoiar o projeto</h2>
                <p className={ styles.boxTextSmall } dangerouslySetInnerHTML={ { __html: _.get(project, 'support') } } />
              </div>
            </Grid>
            <Grid item xs={ 6 }>
              <div className={ styles.boxTextBlock }>
                <h2 className={ styles.titleText }>Contato</h2>
                <p className={ styles.boxTextSmall } dangerouslySetInnerHTML={ { __html: _.get(project, 'contact') } } />
                <p className={ styles.boxTextSmall } dangerouslySetInnerHTML={ { __html: _.get(project, 'website') } } />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </BasePage>
  );
}

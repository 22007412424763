import React, { useState } from 'react';
import ReactEChart from 'echarts-for-react'
import _ from 'lodash';
import chroma from 'chroma-js';

import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import styles from './ProjectsCharts.module.scss';

const mapODSGoalToImage = {
  '1': '#E5253B',
  '2': '#DDA83A',
  '3': '#4C9F39',
  '4': '#C5192D',
  '5': '#FF3A22',
  '6': '#26BDE2',
  '7': '#FCC30D',
  '8': '#A21942',
  '9': '#FD6926',
  '10': '#DD1267',
  '11': '#FD9D24',
  '12': '#BF8B2F',
  '13': '#3F7E44',
  '14': '#0F97D9',
  '15': '#56C02B',
  '16': '#08679D',
  '17': '#19486A',
};

const colorsMap = {
  'PRODUZIR': ['#337bc9', '#184b85'],
  'CONSERVAR': ['#26d2cc', '#0c8c87'],
  'INCLUIR': ['#ffe797', '#fcc300'],
};

function pciTooltipFormatter(params) {
  const { name, seriesName, color, value } = params

  if (_.startsWith(seriesName, 'series')) {
    return `
      <div style="display: flex;max-width: 240px;width: 240px;flex-direction: column;">
        <div style="display: flex;align-items: center;">
          <span style="border-radius:10px;width:10px;height:10px;min-width: 10px;background-color:${color};min-height: 10px;margin-right: 12px;"></span>
          <span style="flex: 1;font-size:14px;color:#656565;font-weight:600;text-transform: uppercase;">${name}</span>
          <span style="font-size:14px;color:#656565;margin-left: auto;font-weight:900;">${value}</span>
        </div>
      </div>
    `;
  }

  return `
    <div style="display: flex;max-width: 240px;width: 240px;flex-direction: column;">
      <div style="display: flex;">
        <span style="border-radius:10px;width:10px;height:10px;min-width: 10px;background-color:${color};min-height: 10px;margin-right: 12px;margin-top: 6px"></span>
        <div style="display: flex;flex-direction: column;">
          <span style="font-size:14px;color:#656565;font-weight:600;margin-bottom: 4px;">${name}</span>
          <span style="font-size:14px;color:#656565;font-weight:400;white-space: initial;">${seriesName}</span>
        </div>
      </div>
      <span style="font-size:14px;color:#656565;margin-left: auto;font-weight:900;">${value}</span>
    </div>
  `;
}

function odsTooltipFormatter(params) {
  const { name, data, color, value } = params

  return `
    <div style="display: flex;max-width: 240px;width: 240px;flex-direction: column;">
      <div style="display: flex;">
        <span style="border-radius:10px;width:10px;height:10px;min-width: 10px;background-color:${color};min-height: 10px;margin-right: 12px;margin-top: 6px"></span>
        <div style="display: flex;flex-direction: column;">
          <span style="font-size:14px;color:#656565;font-weight:600;margin-bottom: 4px;">${name}</span>
          <span style="font-size:14px;color:#656565;font-weight:400;white-space: initial;">${data.description}</span>
        </div>
      </div>
      <span style="font-size:14px;color:#656565;margin-left: auto;font-weight:900;">${value}</span>
    </div>
  `;
}

function PieChart({ series }) {
  const eChartsOption = {
    tooltip: {
      trigger: 'item',
      formatter: odsTooltipFormatter
    },
    series: [
      {
        name: 'Total',
        type: 'pie',
        radius: [50, 120],
        center: ['50%', '50%'],
        data: series,
        itemStyle: {
          borderRadius: 8
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  return (
    <div>
      <ReactEChart
        style={ {
          width: '100%',
          height: '320px',
        } }
        option={ eChartsOption }
      />
    </div>
  )
}

function CustomChart({ categories, series }) {
  return (
    <div>
      <ReactEChart
        style={ {
          width: '100%',
          height: '320px',
        } }
        option={ {
          tooltip: {
            trigger: 'item',
            formatter: pciTooltipFormatter
          },
          xAxis: [
            {
              type: 'category',
              data: categories
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series
        } }
      />
    </div>
  )
}

export default function ProjectsCharts({
  activePci,
  activeOds,
  data
}) {
  const [pciChartType, setPciChartType] = useState('total');

  const pciGoals = _.get(data, 'pci');
  const getPciGoal = (type) => _.get(_.find(pciGoals, { description: type }), 'count');
  let metaSeries = [
    { value: getPciGoal('INCLUIR'), name: 'Incluir', itemStyle: { color: '#fcc300' } },
    { value: getPciGoal('PRODUZIR'), name: 'Produzir', itemStyle: { color: '#184b85' } },
    { value: getPciGoal('CONSERVAR'), name: 'Conservar', itemStyle: { color: '#0c8c87' } },
  ];

  if (!_.isEmpty(activePci)) {
    metaSeries = _.filter(metaSeries, (s) => _.includes(activePci, _.toUpper(s.name)));
  }

  const hasMetaSeries = !_.isEmpty(_.compact(_.map(metaSeries, 'value')));

  const odsGoals = _.get(data, 'ods');
  let odsSeries = _.map(_.orderBy(odsGoals, 'id', 'asc'), (item) => {
    return {
      value: item.count,
      name: `ODS - ${ item.code }`,
      description: item.description,
      itemStyle: {
        color: mapODSGoalToImage[item.code]
      }
    }
  });

  if (!_.isEmpty(activeOds)) {
    odsSeries = _.filter(odsSeries, (s) => {
      const serieNameParsed = Number(_.replace(s.name, 'ODS - ', ''));

      return _.includes(activeOds, serieNameParsed);
    });
  }

  const hasOdsSeries = !_.isEmpty(_.compact(_.map(odsSeries, 'value')));

  const pciGoalsByCategories = _.get(data, 'pciInfos');
  const pciGoalsByCategoriesGrouped = _.groupBy(pciGoalsByCategories, 'typePci');
  let pciKeys = ['INCLUIR', 'PRODUZIR', 'CONSERVAR'];

  if (!_.isEmpty(activePci)) {
    pciKeys = _.filter(pciKeys, (key) => _.includes(activePci, key));
  }

  let pciGoalsByCategoriesSeries = _.map(pciGoalsByCategoriesGrouped, (data, key) => {
    const totalItems = _.size(data);
    const colorsRange = colorsMap[key];
    const classes = chroma.scale(colorsRange).colors(totalItems)

    return _.map(data, (item, index) => {
      const values = _.map(pciKeys, (key) => {
        return item.typePci === key ? {
          value: _.get(item, 'count'),
          itemStyle: {
            color: classes[index]
          }
        } : null;
      });

      return {
        name: item.description,
        type: 'bar',
        stack: 'pci',
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: '#FFFFFF'
        },
        data: values
      };
    });
  });
  pciGoalsByCategoriesSeries = _.flatten(pciGoalsByCategoriesSeries);

  const handlePciChartTypeChange = (event) => {
    setPciChartType(event.target.value);
  };

  return (
    <Grid container spacing={ 2 } className={ styles.container }>
      <Grid item xs={ 6 } className={ styles.containerItem }>
        <h2>Total de metas PCI</h2>
        <FormControl>
          <FormLabel>Modo de visualização</FormLabel>
          <RadioGroup
            row
            name="row-radio-buttons-group"
            value={ pciChartType }
            onChange={ handlePciChartTypeChange }
          >
            <FormControlLabel value="total" control={<Radio />} label="Total" />
            <FormControlLabel value="distributed" control={<Radio />} label="Desagregado" />
          </RadioGroup>
        </FormControl>
        { !hasMetaSeries && <span>Não existem dados disponíveis.</span> }
        { hasMetaSeries && pciChartType === 'total' && <CustomChart categories={ pciKeys } series={ [{ data: metaSeries, type: 'bar' }] } /> }
        { hasMetaSeries && pciChartType === 'distributed' && <CustomChart categories={ pciKeys } series={ pciGoalsByCategoriesSeries } /> }
      </Grid>
      <Grid item xs={ 6 } className={ styles.containerItem }>
        <h2>Total de metas ODS</h2>
        { hasOdsSeries && <PieChart series={ odsSeries } /> }
        { !hasOdsSeries && <span>Não existem dados disponíveis.</span> }
      </Grid>
    </Grid>
  );
}


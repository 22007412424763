import { gql } from '@apollo/client';

export const GET_BASE_DATA = gql`
  {
    toast @client {
      content
      severity
      visible
    }
  }
`;

import React from 'react';
import _ from 'lodash';
import numbro from 'numbro';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import styles from './ProjectsSummary.module.scss';

function formatNumber(value) {
  return numbro(Number(value)).format({
    thousandSeparated: true
  });
}

export default function ProjectsSummary({
  data
}) {
  const propertiesTotal = _.get(data, 'benefitedProperties') || 0;
  const familiesTotal = _.get(data, 'benefitedFamilies') || 0;
  const restoredAreaTotal = _.get(data, 'restoredHectares') || 0;

  return (
    <Paper className={ styles.box }>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 4 }>
          <h2 className={ styles.totalInfo }>
            <span>Propriedades beneficiadas</span>
            <b>{ formatNumber(propertiesTotal) }</b>
          </h2>
        </Grid>
        <Grid item xs={ 4 }>
          <h2 className={ styles.totalInfo }>
            <span>Famílias beneficiadas</span>
            <b>{ formatNumber(familiesTotal) }</b>
          </h2>
        </Grid>
        <Grid item xs={ 4 }>
          <h2 className={ styles.totalInfo }>
            <span>Hectares restaurados</span>
            <b>{ formatNumber(restoredAreaTotal) } ha</b>
          </h2>
        </Grid>
      </Grid>
    </Paper>
  );
}


import { InMemoryCache, makeVar } from '@apollo/client';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        params: {
          read() {
            return paramsVar();
          }
        },
        sankey: {
          read() {
            return sankeyVar();
          }
        },
        user: {
          read() {
            return userVar();
          }
        },
        toast: {
          read() {
            return toastVar();
          }
        },
      }
    }
  }
});

export const paramsVar = makeVar({
  initiative: [],
  territoryType: null,
  territory: null,
  year: null,
  isOnSideBySideMode: false
});

export const sankeyVar = makeVar({
  linkParams: null,
});

export const userVar = makeVar({
  name: null,
  email: null,
  company: null
});

export const toastVar = makeVar({
  content: null,
  severity: null,
  visible: false
});

export default cache;

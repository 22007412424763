import React, { useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Formik, Form, Field } from 'formik';
import { SimpleFileUpload } from 'formik-mui';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import BasePage from '../../layout/BasePage';
import Container from '../../layout/Container';

import toast from '../../utils/toast';

import styles from './ProjectsImportPage.module.scss';

const API_URL = process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:3000';
const token = localStorage.getItem('token') || null;

export function requestAxios(endpoint, data, customConfig) {
  const uploadConfig = {
    url: API_URL + endpoint,
    data,
    headers: { 'Authorization': `Bearer ${ token }` },
  };

  return axios(_.extend(uploadConfig, customConfig))
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (_.get(error, 'response.status') === 401) {
        localStorage.removeItem('token');
        window.location.href = '/';
      }

      toast('Ocorreu um erro.', 'error');

      return Promise.reject(error);
    });
}

function FileForm({
  disabled,
  onSubmit
}) {
  const handleSubmit = (params, { setSubmitting }) => {
    onSubmit(params);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={ {
        file: null
      } }
      onSubmit={ handleSubmit }
      disabled={ disabled }
    >
      { ({ isSubmitting, resetForm, values }) => (
        <Form>
          <div className={ styles.inputWrapper }>
            <Field
              component={ SimpleFileUpload }
              name="file"
              label="Arquivo"
              required
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={ !_.get(values, 'file') }
            >
              Importar
            </Button>
          </div>
        </Form>
      ) }
    </Formik>
  );
}

export default function ProjectsImportPage() {
  const [isImporting, setIsImporting] = useState(false);
  const [hasImported, setHasImported] = useState(false);

  const handleImport = (params) => {
    const formData = new FormData();
    for (const paramKey in params) {
      formData.append(paramKey, params[paramKey]);
    }

    setIsImporting(true);
    setHasImported(false);
    requestAxios('/import', formData, { method: 'post' })
      .then((response) => {
        if (_.get(response, 'message') === 'success') {
          toast('Importação concluída com sucesso!');
          setHasImported(true);
        }
        setIsImporting(false);
      });
  };

  return (
    <BasePage headerSimple>
      <Container>
        <header className={ styles.pageHeader }>
          <div className={ styles.pageHeaderTitleWrapper }>
            <h1>Importar Projetos</h1>
          </div>
        </header>
        <Paper className={ styles.box }>
          <p>Reprehenderit aliqua Lorem voluptate eu tempor. Est laboris culpa eiusmod ea aliquip ad et id. Sit pariatur enim ad do consequat. Nisi incididunt mollit ea id elit proident.</p>
          <FileForm
            disabled={ isImporting }
            onSubmit={ handleImport }
          />
          { isImporting && <p className={ styles.infoText }>Em processo de importação...</p> }
          { hasImported &&
            <div className={ styles.finalInfo }>
              <p className={ styles.infoText }>Visualize os projetos importados.</p>
              <Button variant="contained" color="primary" component={ Link } to="/projetos">Projetos</Button>
            </div>
          }
        </Paper>
      </Container>
    </BasePage>
  );
}


import React from 'react';

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import cache from './apollo-cache';

const API_URL = process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:3000';

const httpLink = createHttpLink({
  uri: API_URL + '/graphql',
});

const cleanSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('name');
  localStorage.removeItem('profile');
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const resetTokenOnUnauthorizedLink = onError(({ networkError }) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    cleanSession()

    window.location.href = '/';
  }
});

const client = new ApolloClient({
  link: from([
    authLink,
    resetTokenOnUnauthorizedLink,
    httpLink
  ]),
  cache,
});

const Provider = ({ children }) => (
  <ApolloProvider client={ client }>
    { children }
  </ApolloProvider>
);

export { client };

export default Provider;

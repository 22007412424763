import React, { useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';

import { Formik, Form, Field } from 'formik';
import { Autocomplete, TextField } from 'formik-mui';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TextFieldMUI from '@mui/material/TextField';

import BasePage from '../../layout/BasePage';
import Container from '../../layout/Container';

import toast from '../../utils/toast';

import placeholderImage from './placeholder-image.jpg';

import {
  GET_FIELDS_DATA,
  GET_BASE_DATA,
  UPDATE_PROJECT,
  SET_PROJECT_STATUS
} from './query';

import styles from './ProjectPage.module.scss'; 

const API_URL = process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:3000';

const fields = [
  { type: 'default', label: 'Título', key: 'name' },
  { type: 'default', label: 'Instituição proponente', key: 'proposingInstitution' },
  { type: 'default', label: 'Instituições parceiras', key: 'partnerInstitutions', multiline: true },
  { type: 'default', label: 'Financiadores', key: 'financiers' },
  { type: 'default', label: 'Objetivo', key: 'objective', multiline: true },
  { type: 'default', label: 'Descrição', key: 'description', multiline: true },
  { type: 'default', label: 'Público-alvo', key: 'targetAudience', multiline: true },
  {
    type: 'ids',
    label: 'Localidade',
    key: 'city',
    autocomplete: true,
    optionsFormatter: (option) => option.name,
    optionsDataKey: 'allCity',
  },
  { type: 'default', label: 'Produtos', key: 'product' },
  { type: 'default', label: 'Duração', key: 'duration', multiline: true },
  { type: 'default', label: 'Meta do Projeto', key: 'projectGoal', multiline: true },
  { type: 'default', label: 'Financiamento/Investimento previsto', key: 'investiment' },
  { type: 'default', label: 'Como apoiar o projeto', key: 'support', multiline: true },
  { type: 'default', label: 'Contato', key: 'contact', multiline: true },
  { type: 'default', label: 'Website', key: 'website' },
];

const metaFields = [
  {
    type: 'ids',
    label: 'Metas PCI',
    key: 'pci',
    autocomplete: true,
    optionsFormatter: (option) => {
      return `${ option.code } - ${ option.typePci } - ${ option.category } - ${ option.description }`;
    },
    optionsDataKey: 'allPci',
  },
  {
    type: 'ids',
    label: 'Metas ODS',
    key: 'ods',
    autocomplete: true,
    optionsFormatter: (option) => {
      return `${ option.code } - ${ option.name }`;
    },
    optionsDataKey: 'allOds',
  },
];

let indicatorFields = [
  { type: 'indicator', category: 'Resultados Alcançados – Informações', label: 'Ano reportado', key: 'yearReport' },
  { type: 'indicator', category: 'Resultados Alcançados – Informações', label: 'Indicadores e métricas', key: 'indicatorsAndMetrics', multiline: true },
  { type: 'indicator', category: 'Resultados Alcançados – Informações', label: 'Financiamento / Investimento realizado', key: 'investmentMade' },
  { type: 'indicator', category: 'Resultados Alcançados – Estatísticas', label: 'Propriedades beneficiadas', key: 'benefitedProperties', valueFormat: 'number' },
  { type: 'indicator', category: 'Resultados Alcançados – Estatísticas', label: 'Famílias beneficiadas', key: 'benefitedFamilies', valueFormat: 'number' },
  { type: 'indicator', category: 'Resultados Alcançados – Estatísticas', label: 'Pastagem recuperada/intensificada', key: 'recoveredPasture', valueFormat: 'number' },
  { type: 'indicator', category: 'Resultados Alcançados – Estatísticas', label: 'Hectares restaurados', key: 'restoredHectares', valueFormat: 'number' },
  { type: 'indicator', category: 'Resultados Alcançados – Estatísticas', label: 'Desmatamento evitado (hectares)', key: 'avoidedDeforestationHa', valueFormat: 'number' },
  { type: 'indicator', category: 'Metas do projeto', label: 'Propriedades beneficiadas', key: 'benefitedProperties', valueFormat: 'number' },
  { type: 'indicator', category: 'Metas do projeto', label: 'Famílias beneficiadas', key: 'benefitedFamilies', valueFormat: 'number' },
  { type: 'indicator', category: 'Metas do projeto', label: 'Pastagem recuperada/intensificada', key: 'recoveredPasture', valueFormat: 'number' },
  { type: 'indicator', category: 'Metas do projeto', label: 'Hectares restaurados', key: 'restoredHectares', valueFormat: 'number' },
  { type: 'indicator', category: 'Metas do projeto', label: 'Desmatamento evitado (hectares)', key: 'avoidedDeforestationHa', valueFormat: 'number' },
  { type: 'indicator', category: 'Financiamento', label: 'Financiamento realizado/em execução (R$)', key: 'financingCarried', valueFormat: 'number' },
  { type: 'indicator', category: 'Financiamento', label: 'Demanda de recursos para escala (R$)', key: 'demandResourcesScale', valueFormat: 'number' },
];
indicatorFields = _.map(indicatorFields, (field) => {
  return {
    ...field,
    key: `indicator-${ field.category }-${ field.key }`
  }
});

const getFieldsByCategory = (category) => _.filter(indicatorFields, { category });

export default function ProjectPage(props) {
  const id = Number(_.get(props, 'match.params.id'));
  const imageRef = useRef(null);
  const [isOnImageUpload, setIsOnImageUpload] = useState(false);
  const { data: fieldsData, loading: loadingFieldsData } = useQuery(GET_FIELDS_DATA);
  const { data: baseData } = useQuery(GET_BASE_DATA, {
    variables: {
      id
    },
  });
  const project = _.first(_.get(baseData, 'searchProject'));
  const active = _.get(project, 'active');
  const indicators = _.get(project, 'indicator');
  const allFields = _.concat(fields, metaFields, indicatorFields);

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onCompleted: (data) => {
      if (_.get(data, 'updateProject.response.id')) {
        toast('Projeto atualizado com sucesso!');
      }
    },
    // onError: () => toast('Ocorreu um erro!', 'error')
  });

  const [updateProjectStatus] = useMutation(SET_PROJECT_STATUS, {
    onCompleted: (data) => {
      if (_.get(data, 'activeProject.response.id')) {
        toast('Projeto atualizado com sucesso!');
      }
      // if (_.get(data, 'runOverlap.status') === true) {
      //   toast('Processamento realizado com sucesso!');
      // }
    },
    // onError: () => toast('Ocorreu um erro!', 'error')
  });

  if (!project) {
    return null;
  }

  let initialValues = _.reduce(allFields, (obj, field) => {
    const { category, key, type } = field;

    if (type === 'ids') {
      const options = _.get(project, key);
      const fieldProps = _.find(allFields, { key });
      const fieldOptions = _.get(fieldsData, _.get(fieldProps, 'optionsDataKey'));
      const value = _.map(options, ({ id }) => _.find(fieldOptions, { id }));

      _.set(obj, key, value);
    } else if (type === 'indicator') {
      const indicatorData = _.find(indicators, { category });
      const indicatorSimpleKey = _.last(_.split(key, '-'));
      const indicatorItemValue = _.get(indicatorData, indicatorSimpleKey);

      _.set(obj, key, indicatorItemValue);
    } else {
      _.set(obj, key, _.get(project, key));
    }
    return obj;
  }, {});

  const handleSubmit = (params, { setSubmitting }) => {
    const projectKeys = _.keys(project);
    let filteredParams = _.pick(params, projectKeys);
    const idsParamsKeys = _.map(_.filter(allFields, { type: 'ids' }), 'key');

    _.each(idsParamsKeys, (key) => {
      const value = _.get(filteredParams, key);
      _.set(filteredParams, key, _.map(value, 'id'));
    });

    let indicatorsClone = _.cloneDeep(_.get(project, 'indicator'));
    const indicatorKeys = _.filter(_.keys(params), (key) => _.includes(key, 'indicator'));
    _.each(indicatorKeys, (key) => {
      const [_name, category, property] = _.split(key, '-');
      const indicatorFieldProps = _.find(indicatorFields, { key });

      indicatorsClone = _.map(indicatorsClone, (indicator) => {
        if (_.get(indicator, 'category') === category) {
          let parsedValue = _.get(params, key);

          if (_.get(indicatorFieldProps, 'valueFormat') === 'number') {
            parsedValue = Number(parsedValue);
          }

          _.set(indicator, property, parsedValue);
        }

        return _.omit(indicator, '__typename');
      });
    });

    updateProject({
      variables: {
        attributes: {
          id,
          ...filteredParams,
          indicators: indicatorsClone
        }
      }
    });
    setSubmitting(false);
  };

  const handleStatusToggle = (event) => {
    updateProjectStatus({
      variables: {
        id,
        active: event.target.checked
      }
    });
  };

  const handleImageInputChange = (event) => {
    const imageFile = event.target.files[0];

    const formData = new FormData();
    formData.append('file', imageFile);
    formData.append('code', _.get(project, 'code'));

    const token = localStorage.getItem('token');

    setIsOnImageUpload(true);
    fetch(API_URL + '/upload_image_project', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    })
      .then((resp) => resp.json())
      .then((data) => {
        setIsOnImageUpload(false);

        if (_.get(data, 'status') === 'success') {
          toast('Imagem do projeto atualizada com sucesso!');

          if (imageRef.current) {
            imageRef.current.file = imageFile;

            const reader = new FileReader();
            reader.onload = (function(aImg) {
              return function(e) {
                aImg.src = e.target.result;
              };
            })(imageRef.current);
            reader.readAsDataURL(imageFile);
          }
        }
      });
  };

  const renderField = (field) => {
    const { key, label, multiline, autocomplete, optionsDataKey, optionsFormatter } = field;
    let extraProps = {};

    if (multiline) {
      extraProps = {
        multiline: true,
        rows: 5
      };
    }

    if (autocomplete) {
      const options = _.get(fieldsData, optionsDataKey);

      return (
        <Grid item xs={ 12 } key={ `field-${ _.kebabCase(key) }` }>
          <Field
            multiple
            name={ key }
            formControl={{ sx: { width: '100%' } }}
            component={ Autocomplete }
            options={ options }
            getOptionLabel={ optionsFormatter }
            renderInput={ (params) => (
              <TextFieldMUI
                { ...params }
                name={ key }
                label={ label }
              />
            )}
          />
        </Grid>
      );
    }

    return (
      <Grid item xs={ 6 } key={ `field-${ _.kebabCase(key) }` }>
        <Field
          fullWidth
          component={ TextField }
          name={ key }
          label={ label }
          InputLabelProps={ {
            shrink: true
          } }
          { ...extraProps }
        />
      </Grid>
    );
  };

  if (loadingFieldsData) {
    return null;
  }

  return (
    <BasePage headerSimple>
      <Container>
        <header className={ styles.pageHeader }>
          <div className={ styles.pageHeaderTitleWrapper }>
            <h1>Editar Projeto</h1>
          </div>
          <div className={ styles.pageHeaderStatus }>
            <span className={ styles.pageHeaderStatusLabel }>Ativo?</span>
            <Switch checked={ active } onChange={ handleStatusToggle } />
          </div>
        </header>
        <Paper className={ styles.box }>
          <div className={ styles.imageBox }>
            <h2 className={ styles.imageBoxTitle }>Imagem</h2>
            <div className={ styles.imageBoxInputWrapper }>
              <input
                type="file"
                accept="image/*"
                onChange={ handleImageInputChange }
              />
              { isOnImageUpload && <CircularProgress size={ 22 } /> }
            </div>
            <img
              ref={ imageRef }
              src={ _.get(project, 'image') ? (API_URL + _.get(project, 'image')) : placeholderImage }
              placeholder="Selecione uma imagem..."
              className={ styles.imageBoxCanva }
            />
          </div>
          <Formik
            initialValues={ initialValues }
            onSubmit={ handleSubmit }
          >
            { ({ isSubmitting }) => (
              <Form>
                <Grid container spacing={ 2 }>
                  { _.map(fields, renderField) }
                </Grid>
                <h2 className={ styles.sectionTitle }>Metas</h2>
                <Grid container spacing={ 2 }>
                  { _.map(metaFields, renderField) }
                </Grid>
                <h2 className={ styles.sectionTitle }>Resultados alcançados</h2>
                <Grid container spacing={ 2 }>
                  { _.map(getFieldsByCategory('Resultados Alcançados – Informações'), renderField) }
                  { _.map(getFieldsByCategory('Resultados Alcançados – Estatísticas'), renderField) }
                </Grid>
                <h2 className={ styles.sectionTitle }>Metas do projeto</h2>
                <Grid container spacing={ 2 }>
                  { _.map(getFieldsByCategory('Metas do projeto'), renderField) }
                </Grid>
                <h2 className={ styles.sectionTitle }>Financiamento</h2>
                <Grid container spacing={ 2 }>
                  { _.map(getFieldsByCategory('Financiamento'), renderField) }
                </Grid>
                <div className={ styles.formButtons }>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            ) }
          </Formik>
        </Paper>
      </Container>
    </BasePage>
  );
}


import React, { useState } from 'react';
import flatten from 'flat';
import numbro from 'numbro';
import _ from 'lodash';

import { IntlProvider } from 'react-intl'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import ApolloProvider from '../apollo';
import Routes from '../Routes';
import generateTheme from '../themes/main';

import ThemeContext from '../../context/ThemeContext';

import ToastContainer from '../../ui/ToastContainer';

import localeMessages from '../../data/localeMessages.js';

numbro.registerLanguage({
  languageTag: 'pt-BR',
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'mil',
    million: 'milhões',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => 'º',
  currency: {
    symbol: 'R$ ',
    position: 'prefix'
  }
});

numbro.setLanguage('pt-BR');

numbro.setDefaults({
  thousandSeparated: true,
  mantissa: 0
});

function Root() {
  const [theme, setTheme] = useState('light');
  const themeValue = { theme, setTheme };
  const locale = localStorage.getItem('locale') || 'pt';
  const themeData = generateTheme(theme);

  return (
    <ApolloProvider>
      <ThemeContext.Provider value={ themeValue }>
        <IntlProvider key={ locale } messages={ flatten(localeMessages[locale]) } locale={ locale }>
          <ThemeProvider theme={ themeData }>
            <StyledEngineProvider injectFirst>
              <Routes />
              <ToastContainer />
            </StyledEngineProvider>
          </ThemeProvider>
        </IntlProvider>
      </ThemeContext.Provider>
    </ApolloProvider>
  )
}

export default Root;

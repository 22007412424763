import React from 'react';
import _ from 'lodash';

import { Formik, Form, Field } from 'formik';
import { Autocomplete, TextField, Select } from 'formik-mui';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextFieldMUI from '@mui/material/TextField';

import styles from './ProjectFilters.module.scss';

const initialValues = {
  title: '',
  pci: [],
  ods: [],
  city: [],
};

export default function ProjectFilters({
  data,
  cities,
  values,
  onSubmit
}) {
  const odsCodes = _.map(_.get(data, 'ods'), 'code');
  const parsedCities = _.orderBy(_.uniqBy(cities, 'code'), 'name');

  const handleSubmit = (params, { setSubmitting }) => {
    onSubmit(params);
    setSubmitting(false);
  };

  const handleReset = (resetForm) => {
    onSubmit(initialValues);
    resetForm();
  };

  return (
    <Paper className={ styles.box }>
      <Formik
        initialValues={ values }
        onSubmit={ handleSubmit }
      >
        { ({ isSubmitting, resetForm }) => (
          <Form>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 4 }>
                <Field
                  fullWidth
                  component={ TextField }
                  name="title"
                  label="Título"
                />
              </Grid>
              <Grid item xs={ 4 }>
                <Field
                  fullWidth
                  multiple
                  formControl={{ sx: { width: '100%' } }}
                  component={ Select }
                  name="pci"
                  label="Metas PCI"
                >
                  <MenuItem value="'INCLUIR'">Incluir</MenuItem>
                  <MenuItem value="'CONSERVAR'">Conservar</MenuItem>
                  <MenuItem value="'PRODUZIR'">Produzir</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={ 4 }>
                <Field
                  fullWidth
                  multiple
                  formControl={{ sx: { width: '100%' } }}
                  component={ Select }
                  name="ods"
                  label="Metas ODS"
                >
                  { _.map(odsCodes, (code) => {
                    return (
                      <MenuItem key={ code } value={ code }>ODS { code }</MenuItem>
                    );
                  }) }
                </Field>
              </Grid>
              <Grid item xs={ 4 }>
                <Field
                  multiple
                  name="city"
                  formControl={{ sx: { width: '100%' } }}
                  component={ Autocomplete }
                  options={ parsedCities }
                  getOptionLabel={ (option) => option.name }
                  renderInput={ (params) => (
                    <TextFieldMUI
                      { ...params }
                      name="city"
                      label="Cidade"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <div className={ styles.formButtons }>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={ handleReset.bind(this, resetForm) }
                className={ styles.resetButton }
              >
                Limpar filtros
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Buscar
              </Button>
            </div>
          </Form>
        ) }
      </Formik>
    </Paper>
  );
}

